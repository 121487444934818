import React, {Component} from 'react'
import {Input} from 'Components/Form'
import {graphql} from 'react-apollo'
import * as compose from 'lodash/flowRight'
import {labels as tagLabels} from 'utils/Tag'
import {gql} from 'graphql.macro'
import {UPDATE_TAG, GET_TAG} from './queries'

import './Settings.scss'

const UPDATE_CALIBRATION = gql`
  mutation UPDATE_CALIBRATION(
    $deviceID: Int!
    $tag: String!
    $fluid: String
    $barrelsPerInch: Float
    $api: Float
    $tds: Float
    $tankHeight: Float
    $kFactor: Float
    $productionOffset: Float
  ) {
    updateCalibration(
      deviceID: $deviceID
      tag: $tag
      fluid: $fluid
      barrelsPerInch: $barrelsPerInch
      api: $api
      tds: $tds
      tankHeight: $tankHeight
      kFactor: $kFactor
      productionOffset: $productionOffset
    ) {
      device {
        id
        tag(tag: $tag) {
          id
          tag
          ymin
          ymax
          alarms {
            lowWarning
            highWarning
            criticalLow
            criticalHigh
          }
          data
          calibration {
            offset
            fluid
            barrelsPerInch
            api
            tds
            tankHeight
            kFactor
            productionOffset
          }
          secondaryGraph {
            deviceID
            tag
          }
          thirdGraph {
            deviceID
            tag
          }
          fourthGraph {
            deviceID
            tag
          }
          fifthGraph {
            deviceID
            tag
          }
        }
      }
    }
  }
`

export const GET_DEVICES_SCRATCH_SETTINGS = gql`
  query GET_DEVICES_SETTINGS($includeInactive: Boolean, $includeOOS: Boolean) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    devicesScratch(includeInactive: $includeInactive, includeOOS: $includeOOS) {
      id
      userAssignedID
      name
      path
      tags {
        id
        deviceID
        tag
        ymin
        ymax
        time
        value
        filterEnabled
        displayUnits
        calibration {
          fluid
        }
        alarms {
          criticalLow
          criticalHigh
          lowWarning
          highWarning
          flag
        }
      }
    }
  }
`
const calibrationDefaults = {
  time: 0,
  fluid: 'water',
  tds: 24,
  api: 32,
  sg: 1,
  barrelsPerInch: 1.67,
  target: 0,
  kFactor: 1,
  productionOffset: 0,
  orifice_diameter: null,
  pipe_diameter: null,
  mu: 0.2825,
  rho_f: 58.792,
  k_factor_flow: 1.3
}

const getInitialState = ({Tag, additionalTags}) => ({
  ymin: Tag.ymin,
  ymax: Tag.ymax,
  displayUnits: Tag.display.value,
  secondaryGraph: Tag.secondaryGraph
    ? Tag.secondaryGraph.deviceID + '/' + Tag.secondaryGraph.tag
    : null,
  thirdGraph: Tag.thirdGraph
    ? Tag.thirdGraph.deviceID + '/' + Tag.thirdGraph.tag
    : null,
  fourthGraph: Tag.fourthGraph
    ? Tag.fourthGraph.deviceID + '/' + Tag.fourthGraph.tag
    : null,
  fifthGraph: Tag.fifthGraph
    ? Tag.fifthGraph.deviceID + '/' + Tag.fifthGraph.tag
    : null,
  editing: false,
  tankHeight: Tag.calibration.tankHeight,
  kFactor: Tag.calibration.kFactor,
  productionOffset: Tag.calibration.productionOffset,
  additionalTags: additionalTags.map((aTag) => ({
    tag: aTag.tag,
    displayUnits: aTag.displayUnits,
    ymin: aTag.ymin,
    ymax: aTag.ymax
  })),
  filterEnabled: Tag.filterEnabled,
  filterThreshold: Tag.filterThreshold,
  filterMinimumValue: Tag.filterMinimumValue,
  sensorTempFilterEnabled: Tag.sensorTempFilterEnabled,
  sensorTempFilterThreshold: Tag.sensorTempFilterThreshold,
  temperatureCorrectionEnabled: Tag.temperatureCorrectionEnabled,
  temperatureCorrectionThreshold: Tag.temperatureCorrectionThreshold || null,
  sf6NormalizationEnabled: Tag.sf6NormalizationEnabled,
  fourierPlotEnabled: Tag.fourierPlotEnabled,
  maximumFourierAmplitude: Tag.maximumFourierAmplitude
})

class Settings extends Component {
  state = getInitialState(this.props)
  componentDidUpdate(prevProps) { 
    if (prevProps.Tag !== this.props.Tag)
      this.setState(getInitialState(this.props))
  }
  render() {
    const {Tag, me} = this.props
    const allTags = (this.props.data.devicesScratch || []).reduce(
      (arr, d) => arr.concat(d.tags.map((t) => ({...t, device: d}))),
      []
    )

    return (
      <div className="tag-settings grid">
        <div className="box tag-settings-display">
          <div className="title">
            <div className="left">Data Display</div>

            {this.state.editing !== 'settings' && (
              <div className="right">
                {me.canEdit && (
                  <button onClick={() => this.setState({editing: 'settings'})}>
                    Edit
                  </button>
                )}
              </div>
            )}
            {this.state.editing === 'settings' && (
              <div className="right">
                <button
                  onClick={() => this.setState(getInitialState(this.props))}
                >
                  Cancel
                </button>{' '}
                <button
                  className="primary"
                  onClick={() => {
                    this.props.updateTag({
                      variables: {
                        deviceID: this.props.device.id,
                        tag: Tag.tag,
                        ymin: this.state.ymin,
                        ymax: this.state.ymax,
                        displayUnits: this.state.displayUnits,
                        secondaryGraphDeviceID:
                          this.state.secondaryGraph &&
                          this.state.secondaryGraph.split('/')[0] * 1,
                        secondaryGraphTag:
                          this.state.secondaryGraph &&
                          this.state.secondaryGraph.split('/')[1],
                        thirdGraphDeviceID:
                          this.state.thirdGraph &&
                          this.state.thirdGraph.split('/')[0] * 1,
                        thirdGraphTag:
                          this.state.thirdGraph &&
                          this.state.thirdGraph.split('/')[1],
                        fourthGraphDeviceID:
                          this.state.fourthGraph &&
                          this.state.fourthGraph.split('/')[0] * 1,
                        fourthGraphTag:
                          this.state.fourthGraph &&
                          this.state.fourthGraph.split('/')[1],
                        fifthGraphDeviceID:
                          this.state.fifthGraph &&
                          this.state.fifthGraph.split('/')[0] * 1,
                        fifthGraphTag:
                          this.state.fifthGraph &&
                          this.state.fifthGraph.split('/')[1],
                        additionalTags: this.state.additionalTags,
                        filterEnabled: this.state.filterEnabled,
                        filterThreshold: this.state.filterThreshold,
                        filterMinimumValue: this.state.filterMinimumValue,
                        sensorTempFilterEnabled:
                          this.state.sensorTempFilterEnabled,
                        sensorTempFilterThreshold:
                          parseInt(this.state.sensorTempFilterThreshold) || 20,
                        temperatureCorrectionEnabled:
                          this.state.temperatureCorrectionEnabled,
                        temperatureCorrectionThreshold:
                          this.state.temperatureCorrectionThreshold || null,
                        sf6NormalizationEnabled:
                          this.state.sf6NormalizationEnabled,
                        fourierPlotEnabled: this.state.fourierPlotEnabled,
                        maximumFourierAmplitude:
                          this.state.maximumFourierAmplitude
                      },
                      refetchQueries: [
                        {
                          query: GET_TAG,
                          variables: {
                            deviceID: this.props.device.id,
                            tag: Tag.tag
                          }
                        }
                      ]
                    })
                    this.setState(getInitialState(this.props))
                  }}
                >
                  Save
                </button>
              </div>
            )}
          </div>
          <Input
            type="select"
            label="Display Units"
            options={Tag.displayOptions.map(({label, value}) => ({
              label,
              value
            }))}
            value={this.state.displayUnits}
            onChange={(e) => {
              this.setState({
                displayUnits: e.target.value,
                ymin: Tag.displayOptions.find((o) => o.value === e.target.value)
                  .ymin,
                ymax: Tag.displayOptions.find((o) => o.value === e.target.value)
                  .ymax
              })
            }}
            static={this.state.editing !== 'settings'}
          />
          <Input
            type={Tag.display.input || 'float'}
            label="Y Axis Minimum"
            value={this.state.ymin}
            render={Tag.render}
            onChange={(e) => this.setState({ymin: e.target.value})}
            static={this.state.editing !== 'settings'}
          />
          <Input
            type={Tag.display.input || 'float'}
            label="Y Axis Maximum"
            value={this.state.ymax}
            render={Tag.render}
            onChange={(e) => this.setState({ymax: e.target.value})}
            static={this.state.editing !== 'settings'}
          />
          {(!!me.isSuperUser || !!me.isManager) &&
            (Tag.tag === 'psr' ||
              Tag.tag === 'spsr' ||
              Tag.tag === 'dspsr' ||
              Tag.tag === 'sonr') && (
              <Input
                type="select"
                label="Temperature Compensation Filter"
                tooltip={
                  Tag.tag === 'psr'
                    ? 'Select SF6 Filling Pressure for isolated environments filled with SF6.  Basic Temperature Compensation can be selected for other applications.'
                    : 'Basic Temperature Compensation can be selected to adjust for temperature effects on sensor readings.'
                }
                value={
                  !this.state.temperatureCorrectionEnabled &&
                  !this.state.sf6NormalizationEnabled
                    ? 'none'
                    : !!this.state.temperatureCorrectionEnabled
                    ? 'basic'
                    : 'sf6'
                }
                static={this.state.editing !== 'settings'}
                options={
                  Tag.tag === 'psr'
                    ? [
                        {label: 'None', value: 'none'},
                        {
                          label: 'Basic Temperature Compensation',
                          value: 'basic'
                        },
                        {label: 'SF6 Filling Pressure', value: 'sf6'}
                      ]
                    : [
                        {label: 'None', value: 'none'},
                        {
                          label: 'Basic Temperature Compensation',
                          value: 'basic'
                        }
                      ]
                }
                onChange={(e) => {
                  if (e.target.value === 'none') {
                    this.setState({
                      temperatureCorrectionEnabled: false,
                      sf6NormalizationEnabled: false
                    })
                  } else if (e.target.value === 'basic') {
                    this.setState({
                      temperatureCorrectionEnabled: true,
                      sf6NormalizationEnabled: false
                    })
                  } else {
                    this.setState({
                      temperatureCorrectionEnabled: false,
                      sf6NormalizationEnabled: true
                    })
                  }
                }}
              />
            )}

          {(!!me.isSuperUser || !!me.isManager) &&
            (Tag.tag === 'psr' ||
              Tag.tag === 'spsr' ||
              Tag.tag === 'dspsr' ||
              Tag.tag === 'sonr') && (
              <Input
                type="checkbox"
                label="Temperature Correction Enabled"
                tooltip={`Check this option to enable temperature correction.`}
                onChange={() =>
                  this.setState({
                    temperatureCorrectionEnabled:
                      !this.state.temperatureCorrectionEnabled
                  })
                }
                data-key="temperatureCorrectionEnabled"
                value={this.state.temperatureCorrectionEnabled}
                static={!this.state.editing}
              />
            )}

          {(!!me.isSuperUser || !!me.isManager) && (
            <Input
              type="checkbox"
              label="Erroneous Data Filter Enabled"
              tooltip={`Check this option to enable filtering of erroneous data.`}
              onChange={() =>
                this.setState({
                  filterEnabled: !this.state.filterEnabled
                })
              }
              data-key="filterEnabled"
              value={this.state.filterEnabled}
              static={!this.state.editing}
            />
          )}
          {(!!me.isSuperUser || !!me.isManager) && (
            <Input
              type={Tag.display.input || 'float'}
              label="Erroneous Data Filter Threshold"
              tooltip={`Values that exceed this absolute difference are subject to the data filter.`}
              value={this.state.filterThreshold}
              render={Tag.render}
              onChange={(e) => this.setState({filterThreshold: e.target.value})}
              static={this.state.editing !== 'settings'}
            />
          )}
          {(!!me.isSuperUser || !!me.isManager) && (
            <Input
              type="checkbox"
              label="Erroneous Data Filter Enabled - Sensor Temperature"
              tooltip={`Check this option to enable filtering of erroneous sensor temperature data.`}
              onChange={() =>
                this.setState({
                  sensorTempFilterEnabled: !this.state.sensorTempFilterEnabled
                })
              }
              data-key="sensorTempFilterEnabled"
              value={this.state.sensorTempFilterEnabled}
              static={!this.state.editing}
            />
          )}
          {(!!me.isSuperUser || !!me.isManager) && (
            <Input
              type={'integer'}
              label="Erroneous Data Filter Threshold - Sensor Temperature"
              tooltip={`Values that exceed this absolute difference are removed from sensor temperature data.`}
              value={this.state.sensorTempFilterThreshold}
              render={Tag.render}
              onChange={(e) =>
                this.setState({
                  sensorTempFilterThreshold: e.target.value
                })
              }
              static={this.state.editing !== 'settings'}
            />
          )}
          {(!!me.isSuperUser || !!me.isManager) &&
            (Tag.tag === 'psr' ||
              Tag.tag === 'spsr' ||
              Tag.tag === 'dspsr' ||
              Tag.tag === 'sonr') &&
            !!this.state.temperatureCorrectionEnabled && (
              <Input
                type={
                  this.props.additionalTags.find((aTag) => aTag.tag === 'temp')
                    .display.input || 'float'
                }
                label="Temperature Correction Threshold"
                tooltip={`The graph will attempt to omit changes in temperature greater than this amount from temperature correction.`}
                value={this.state.temperatureCorrectionThreshold}
                render={
                  this.props.additionalTags.find((aTag) => aTag.tag === 'temp')
                    .render
                }
                onChange={(e) =>
                  this.setState({
                    temperatureCorrectionThreshold: e.target.value
                  })
                }
                static={this.state.editing !== 'settings'}
              />
            )}
          {(!!me.isSuperUser || !!me.isManager) && Tag.tag === 'vib' && (
            <Input
              type="checkbox"
              label="Fourier Transform Data Display"
              tooltip={`Check this option to display amplitude as a function of frequency and time (vibration sensors only).`}
              onChange={() =>
                this.setState({
                  fourierPlotEnabled: !this.state.fourierPlotEnabled
                })
              }
              data-key="fourierPlotEnabled"
              value={this.state.fourierPlotEnabled}
              static={!this.state.editing}
            />
          )}
          {(!!me.isSuperUser || !!me.isManager) && Tag.tag === 'vib' && (
            <Input
              type="number"
              label="Fourier Data Display Maximum Amplitude"
              tooltip={`Use this option to manually set the maximum amplitude value the Fourier Display will graph. Otherwise the value is dynamically set. (vibration sensors only).`}
              onChange={(e) =>
                this.setState({
                  maximumFourierAmplitude: e.target.value
                })
              }
              data-key="maximumFourierAmplitude"
              value={this.state.maximumFourierAmplitude}
              static={!this.state.editing}
            />
          )}
          {!!me.isSuperUser && (
            <Input
              type={Tag.display.input || 'float'}
              label="Filter Minimum Value"
              tooltip={`Values that are less than this are subject to the data filter.`}
              value={
                this.state.filterMinimumValue === null ||
                this.state.filterMinimumValue === ''
                  ? null
                  : this.state.filterMinimumValue
              }
              render={Tag.render}
              onChange={(e) =>
                this.setState({
                  filterMinimumValue:
                    e.target.value === null || e.target.value === ''
                      ? null
                      : e.target.value
                })
              }
              static={this.state.editing !== 'settings'}
            />
          )}
          {
            <Input
              label="Secondary Graph"
              tooltip="Plot a second data line on the same graph."
              type="select"
              value={this.state.secondaryGraph}
              options={[]
                .concat([{label: 'None', value: null}])
                .concat(
                  allTags
                    .filter(
                      (t) =>
                        t.device.path === this.props.device.path &&
                        !(
                          t.device.id === this.props.device.id &&
                          t.tag === Tag.tag
                        )
                    )
                    .map((t) => ({
                      label:
                        t.device.path +
                        '/' +
                        t.device.name +
                        ' : ' +
                        tagLabels[t.tag],
                      value: t.device.id + '/' + t.tag
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))
                )
                .concat({
                  label: '',
                  value: false,
                  disabled: true
                })
                .concat(
                  (allTags || [])
                    .filter(
                      (t) =>
                        !(
                          t.device.id === this.props.device.id &&
                          t.tag === Tag.tag
                        )
                    )
                    .map((t) => ({
                      label:
                        t.device.path +
                        '/' +
                        t.device.name +
                        ' : ' +
                        tagLabels[t.tag],
                      value: t.device.id + '/' + t.tag
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))
                )}
              onChange={(e) => this.setState({secondaryGraph: e.target.value})}
              static={this.state.editing !== 'settings'}
            />
          }
          {
            <Input
              label="Third Graph"
              tooltip="Plot a second data line on the same graph."
              type="select"
              value={this.state.thirdGraph}
              options={[]
                .concat([{label: 'None', value: null}])
                .concat(
                  allTags
                    .filter(
                      (t) =>
                        t.device.path === this.props.device.path &&
                        !(
                          t.device.id === this.props.device.id &&
                          t.tag === Tag.tag
                        )
                    )
                    .map((t) => ({
                      label:
                        t.device.path +
                        '/' +
                        t.device.name +
                        ' : ' +
                        tagLabels[t.tag],
                      value: t.device.id + '/' + t.tag
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))
                )
                .concat({
                  label: '',
                  value: false,
                  disabled: true
                })
                .concat(
                  (allTags || [])
                    .filter(
                      (t) =>
                        !(
                          t.device.id === this.props.device.id &&
                          t.tag === Tag.tag
                        )
                    )
                    .map((t) => ({
                      label:
                        t.device.path +
                        '/' +
                        t.device.name +
                        ' : ' +
                        tagLabels[t.tag],
                      value: t.device.id + '/' + t.tag
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))
                )}
              onChange={(e) => this.setState({thirdGraph: e.target.value})}
              static={this.state.editing !== 'settings'}
            />
          }
          {
            <Input
              label="Fourth Graph"
              tooltip="Plot a second data line on the same graph."
              type="select"
              value={this.state.fourthGraph}
              options={[]
                .concat([{label: 'None', value: null}])
                .concat(
                  allTags
                    .filter(
                      (t) =>
                        t.device.path === this.props.device.path &&
                        !(
                          t.device.id === this.props.device.id &&
                          t.tag === Tag.tag
                        )
                    )
                    .map((t) => ({
                      label:
                        t.device.path +
                        '/' +
                        t.device.name +
                        ' : ' +
                        tagLabels[t.tag],
                      value: t.device.id + '/' + t.tag
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))
                )
                .concat({
                  label: '',
                  value: false,
                  disabled: true
                })
                .concat(
                  (allTags || [])
                    .filter(
                      (t) =>
                        !(
                          t.device.id === this.props.device.id &&
                          t.tag === Tag.tag
                        )
                    )
                    .map((t) => ({
                      label:
                        t.device.path +
                        '/' +
                        t.device.name +
                        ' : ' +
                        tagLabels[t.tag],
                      value: t.device.id + '/' + t.tag
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))
                )}
              onChange={(e) => this.setState({fourthGraph: e.target.value})}
              static={this.state.editing !== 'settings'}
            />
          }
          {
            <Input
              label="Fifth Graph"
              tooltip="Plot a second data line on the same graph."
              type="select"
              value={this.state.fifthGraph}
              options={[]
                .concat([{label: 'None', value: null}])
                .concat(
                  allTags
                    .filter(
                      (t) =>
                        t.device.path === this.props.device.path &&
                        !(
                          t.device.id === this.props.device.id &&
                          t.tag === Tag.tag
                        )
                    )
                    .map((t) => ({
                      label:
                        t.device.path +
                        '/' +
                        t.device.name +
                        ' : ' +
                        tagLabels[t.tag],
                      value: t.device.id + '/' + t.tag
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))
                )
                .concat({
                  label: '',
                  value: false,
                  disabled: true
                })
                .concat(
                  (allTags || [])
                    .filter(
                      (t) =>
                        !(
                          t.device.id === this.props.device.id &&
                          t.tag === Tag.tag
                        )
                    )
                    .map((t) => ({
                      label:
                        t.device.path +
                        '/' +
                        t.device.name +
                        ' : ' +
                        tagLabels[t.tag],
                      value: t.device.id + '/' + t.tag
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))
                )}
              onChange={(e) => this.setState({fifthGraph: e.target.value})}
              static={this.state.editing !== 'settings'}
            />
          }
          {this.props.additionalTags.map((aTagNode) => {
            let label = null
            if (aTagNode.tag === 'temp') {
              label = 'Sensor Temperature'
            } else if (aTagNode.tag === 'cumulative_pulses') {
              label = 'Cumulative Production'
            } else if (aTagNode.tag === 'uv_pulses') { // mv pulses now. leaving since this is a quick hack
              label = 'Pulse amplitude (mV)'
            } else if (aTagNode.tag === 'pdiff') {
              label = 'Differential Pressure'
            } else if (aTagNode.tag === 'hpres') {
              label = 'Vapor Pressure'
            } else if (aTagNode.tag === 'htemp') {
              label = 'Vapor Temperature'
            } else if (aTagNode.tag === 'tpres') {
              label = 'Barometric Pressure'
            } else if (aTagNode.tag === 'ttemp') {
              label = 'Transmitter Temperature'
            } else {
              label = 'Additional Tag Data'
            }
            return (
              <div key={`${aTagNode.tag}+div`}>
                <Input
                  label={`Display Units - ${label}`}
                  static={this.state.editing !== 'settings'}
                  tooltip="Display Units for Additional Tag Data Display"
                  key={aTagNode.tag}
                  type="select"
                  value={
                    this.state.additionalTags.find(
                      (aTag) => aTag.tag === aTagNode.tag
                    ).displayUnits
                  }
                  options={aTagNode.displayOptions.map(({label, value}) => ({
                    label,
                    value
                  }))}
                  onChange={(e) => {
                    let newTag = this.state.additionalTags.find(
                      (aTag) => aTag.tag === aTagNode.tag
                    )
                    newTag.displayUnits = e.target.value
                    this.setState({
                      additionalTags: [
                        ...this.state.additionalTags.filter(
                          (aTag) => aTag.tag !== aTagNode.tag
                        ),
                        newTag
                      ]
                    })
                  }}
                ></Input>
                <Input
                  label={`Y Axis Minimum - ${label}`}
                  static={this.state.editing !== 'settings'}
                  tooltip="Y Axis Minimum for Additional Tag Data Display"
                  key={`${aTagNode.tag}+ymin`}
                  type={aTagNode.display.input || 'float'}
                  value={
                    this.state.additionalTags.find(
                      (aTag) => aTag.tag === aTagNode.tag
                    ).ymin
                  }
                  options={aTagNode.displayOptions.map(({label, value}) => ({
                    label,
                    value
                  }))}
                  onChange={(e) => {
                    let newTag = this.state.additionalTags.find(
                      (aTag) => aTag.tag === aTagNode.tag
                    )
                    newTag.ymin = e.target.value
                    this.setState({
                      additionalTags: [
                        ...this.state.additionalTags.filter(
                          (aTag) => aTag.tag !== aTagNode.tag
                        ),
                        newTag
                      ]
                    })
                  }}
                ></Input>{' '}
                <Input
                  label={`Y Axis Maximum - ${label}`}
                  static={this.state.editing !== 'settings'}
                  tooltip="Y Axis Maximum for Additional Tag Data Display"
                  key={`${aTagNode.tag}+ymax`}
                  type={aTagNode.display.input || 'float'}
                  value={
                    this.state.additionalTags.find(
                      (aTag) => aTag.tag === aTagNode.tag
                    ).ymax
                  }
                  options={aTagNode.displayOptions.map(({label, value}) => ({
                    label,
                    value
                  }))}
                  onChange={(e) => {
                    let newTag = this.state.additionalTags.find(
                      (aTag) => aTag.tag === aTagNode.tag
                    )
                    newTag.ymax = e.target.value
                    this.setState({
                      additionalTags: [
                        ...this.state.additionalTags.filter(
                          (aTag) => aTag.tag !== aTagNode.tag
                        ),
                        newTag
                      ]
                    })
                  }}
                ></Input>
              </div>
            )
          })}
        </div>

        {Tag.tag === 'dist' && (
          <div className="box tag-settings-display">
            <div className="title">
              <div className="left">Radar Calibration Settings</div>

              {this.state.editing !== 'radar' && (
                <div className="right">
                  {me.canEdit && (
                    <button onClick={() => this.setState({editing: 'radar'})}>
                      Edit
                    </button>
                  )}
                </div>
              )}
              {this.state.editing === 'radar' && (
                <div className="right">
                  <button
                    onClick={() => this.setState(getInitialState(this.props))}
                  >
                    Cancel
                  </button>{' '}
                  <button
                    className="primary"
                    onClick={() => {
                      this.props.updateCalibration({
                        variables: {
                          ...calibrationDefaults,
                          tankHeight: this.state.tankHeight,
                          deviceID: Tag.deviceID,
                          tag: Tag.tag
                        }
                      })
                      this.setState(getInitialState(this.props))
                    }}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <Input
              label="Tank Height"
              tooltip="Radar sensors require calibration of tank height."
              value={this.state.tankHeight ? this.state.tankHeight / 12 : null}
              type="feetinches"
              render={Tag.render}
              onChange={(e) => this.setState({tankHeight: e.target.value * 12})}
              static={this.state.editing !== 'radar'}
            />
          </div>
        )}
        {Tag.tag === 'sonr' && (
          <div className="box tag-settings-display">
            <div className="title">
              <div className="left">Sonar Calibration Settings</div>

              {this.state.editing !== 'sonar' && (
                <div className="right">
                  {me.canEdit && (
                    <button onClick={() => this.setState({editing: 'sonar'})}>
                      Edit
                    </button>
                  )}
                </div>
              )}
              {this.state.editing === 'sonar' && (
                <div className="right">
                  <button
                    onClick={() => this.setState(getInitialState(this.props))}
                  >
                    Cancel
                  </button>{' '}
                  <button
                    className="primary"
                    onClick={() => {
                      this.props.updateCalibration({
                        variables: {
                          ...calibrationDefaults,
                          tankHeight: this.state.tankHeight,
                          deviceID: Tag.deviceID,
                          tag: Tag.tag
                        }
                      })
                      this.setState(getInitialState(this.props))
                    }}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <Input
              label="Tank Height"
              tooltip="Sonar sensors require calibration of tank height."
              value={this.state.tankHeight ? this.state.tankHeight / 12 : null}
              type="feetinches"
              render={Tag.render}
              onChange={(e) => this.setState({tankHeight: e.target.value * 12})}
              static={this.state.editing !== 'sonar'}
            />
          </div>
        )}
        {Tag.tag === 'puls' && (
          <div className="box tag-settings-display">
            <div className="title">
              <div className="left">Turbine Calibration Settings</div>

              {this.state.editing !== 'turbine' && (
                <div className="right">
                  {me.canEdit && (
                    <button onClick={() => this.setState({editing: 'turbine'})}>
                      Edit
                    </button>
                  )}
                </div>
              )}
              {this.state.editing === 'turbine' && (
                <div className="right">
                  <button
                    onClick={() => this.setState(getInitialState(this.props))}
                  >
                    Cancel
                  </button>{' '}
                  <button
                    className="primary"
                    onClick={() => {
                      this.props.updateCalibration({
                        variables: {
                          ...calibrationDefaults,
                          kFactor: this.state.kFactor,
                          productionOffset: this.state.productionOffset,
                          deviceID: Tag.deviceID,
                          tag: Tag.tag
                        },
                        refetchQueries: [
                          {
                            query: GET_TAG,
                            variables: {
                              deviceID: this.props.device.id,
                              tag: Tag.tag
                            }
                          }
                        ]
                      })
                      this.setState(getInitialState(this.props))
                    }}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <Input
              label="k-factor"
              tooltip="Turbine sensors require calibration of k-factor, the number of turbine revolutions per gallon of fluid."
              value={this.state.kFactor}
              type="float"
              render={(v) => Tag.display.k_factor_unit(v)}
              onChange={(e) => this.setState({kFactor: e.target.value})}
              static={this.state.editing !== 'turbine'}
            />
            <Input
              label="Cumulative production offset"
              tooltip="Provide a value (positive or negative) in bbls to offset reported cumulative production values."
              value={this.state.productionOffset}
              type="float"
              render={(v) => Tag.display.k_factor_unit(v)}
              onChange={(e) =>
                this.setState({productionOffset: e.target.value})
              }
              static={this.state.editing !== 'turbine'}
            />
            <p></p>
            <p>** For fluid K-factor = pulses(rev)/gallon</p>
            <p>** For fluid K-factor = ft^3/pulse(rev)</p>
          </div>
        )}
        {Tag.tag === 'pult' && (
          <div className="box tag-settings-display">
            <div className="title">
              <div className="left">Turbine Calibration Settings</div>

              {this.state.editing !== 'turbine' && (
                <div className="right">
                  {me.canEdit && (
                    <button onClick={() => this.setState({editing: 'turbine'})}>
                      Edit
                    </button>
                  )}
                </div>
              )}
              {this.state.editing === 'turbine' && (
                <div className="right">
                  <button
                    onClick={() => this.setState(getInitialState(this.props))}
                  >
                    Cancel
                  </button>{' '}
                  <button
                    className="primary"
                    onClick={() => {
                      this.props.updateCalibration({
                        variables: {
                          ...calibrationDefaults,
                          kFactor: this.state.kFactor,
                          deviceID: Tag.deviceID,
                          tag: Tag.tag
                        }
                      })
                      this.setState(getInitialState(this.props))
                    }}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <Input
              label="k-factor"
              tooltip="Turbine sensors require calibration of k-factor, the number of turbine revolutions per gallon of fluid."
              value={this.state.kFactor}
              type="float"
              render={(v) => `${v} rev/gal`}
              onChange={(e) => this.setState({kFactor: e.target.value})}
              static={this.state.editing !== 'turbine'}
            />
          </div>
        )}
      </div>
    )
  }
}

export default compose(
  graphql(UPDATE_TAG, {name: 'updateTag'}),
  graphql(UPDATE_CALIBRATION, {name: 'updateCalibration'}),
  graphql(GET_DEVICES_SCRATCH_SETTINGS)
)(Settings)
