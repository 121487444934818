const formatEpochTime = (epochTime) => {
    // Convert epoch time from seconds to milliseconds
    const date = new Date(epochTime * 1000);
  
    // Extract date and time components
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    // Format as mm/dd/yyyy hh:mm:ss
    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  };
export default formatEpochTime